import React, { useState, useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { createAccount } from '../../api/auth';
import './create-account-form.scss';


import Assist from '../../assist';
import TaskResult from "../../classes/taskresult.js";
import AppInfo from '../../app-info.js';
import axios from "axios";

export default function CreateAccount(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { reporterMobile, reporterEmail,  suspectName, suspectPosition, witnessName, witnessPosition, witnessMobile, 
      witnessEmail, complaint, complaintDate, complaintLocation, complaintCause} = formData.current;
    setLoading(true);



    let dataP = JSON.stringify({
      "status": "Submitted",
      "reporterMobile": reporterMobile,
      "reporterEmail":reporterEmail,
      "suspectName":suspectName,
      "suspectPosition": suspectPosition,
      "witnessName": witnessName,
      "witnessPosition": witnessPosition,
      "witnessMobile": witnessMobile,
      "witnessEmail":  witnessEmail,
      "complaint": complaint,
      "complaintActor": complaintDate,
      "complaintDate": "2024-02-01T17:24:51",
      "complaintLocation": complaintLocation,
      "complaintCause":  complaintCause,
      "submittedDate": "2024-02-01T12:24:51",
      "reviewDate": "2024-02-01T13:24:51",
      "reviewUser": "mike",
      "type": "Phone"
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://admin.report-zm.online/api/report/create',
      headers: { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json'
      },
      data : dataP
    };

    console.log('Starting the Response');

    axios.request(config)
    .then((response) => {
      console.log('Response: ' + JSON.stringify(response.data));
      setLoading(false);
      Assist.showMessage('The complaint has been successfully added!',  'info');
    })
    .catch((error) => {
      console.log('Response: ' + JSON.stringify(error));
      Assist.showMessage('The complaint could not be added due to an error', 'error');
      setLoading(false);
    });



  }, [history]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading} colCount={3}>
        <Item
          dataField={'reporterMobile'}
          editorType={'dxTextBox'}
          text={'0977223344'}
          
          editorOptions={{ stylingMode: 'filled', placeholder: 'Your Mobile', mode: 'text' }}
        >
          <Label visible={true} text={'Your mobile (optional)'} />
        </Item>
        <Item
          dataField={'reporterEmail'}
          editorType={'dxTextBox'}
          text={'nkoleevans@hotmail.com'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Your Email', mode: 'email' }}
        >
          <EmailRule message="Email is invalid" />
          <Label visible={true} text={'Your email address (optional)'} />
        </Item>

        <Item
          dataField={'suspectName'}
          editorType={'dxTextBox'}
          text={'Mike Banda'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Suspect name', mode: 'text' }}
        >
          <Label visible={true} text={'Suspect Name'} />
        </Item>
        <Item
          dataField={'suspectPosition'}
          editorType={'dxTextBox'}
          text={'Manager'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Suspect Position', mode: 'text' }}
        >
          <Label visible={true} text={'Suspect Position'} />
        </Item>
        <Item
          dataField={'witnessName'}
          editorType={'dxTextBox'}
          text={'Rita Choolwe'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Witness Name', mode: 'text' }}
        >
          <Label visible={true} text={'Witness Name'} />
        </Item>
        <Item
          dataField={'witnessPosition'}
          editorType={'dxTextBox'}
          text={'Assistant'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Witness Position', mode: 'text' }}
        >
          <Label visible={true} text={'Witness Position'} />
        </Item>
        <Item
          dataField={'witnessMobile'}
          editorType={'dxTextBox'}
          text={'0955678934'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Witness Mobile', mode: 'text' }}
        >
          <Label visible={true} text={'Witness Mobile'} />
        </Item>
        <Item
          dataField={'witnessEmail'}
          editorType={'dxTextBox'}
          text={'rita@yahoo.com'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Witness Email', mode: 'text' }}
        >
          <Label visible={true} text={'Witness Email'} />
        </Item>
        <Item colSpan={3}
          dataField={'complaint'}
          editorType={'dxTextBox'}
          text={'Tried to obtain a bribe'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Complaint', mode: 'text' }}
        >
          <Label visible={true} text={'What wrongdoing occurred?'} />
        </Item>
        <Item
          dataField={'complaintDate'}
          editorType={'dxDateBox'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Complaint Date', mode: 'text' }}
        >
          <Label visible={true} text={'When did this occur?'} />
        </Item>
        <Item
          dataField={'complaintLocation'}
          editorType={'dxTextBox'}
          text={'0Lusaka, Makeni Mall'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Complaint Location', mode: 'text' }}
        >
          <Label visible={true} text={'Where did this happen? (Department)'} />
        </Item>
        <Item
          dataField={'complaintCause'}
          editorType={'dxTextBox'}
          text={'Making payment'}
          editorOptions={{ stylingMode: 'filled', placeholder: 'Complaint Cause', mode: 'text' }}
        >
          <Label visible={true} text={'What enabled this to happen (How)?'} />
        </Item>
        <Item colSpan={3}>
          <div className='policy-info'>
            By creating an account, you agree to the <Link to="#">Terms of Service</Link> and <Link to="#">Privacy Policy</Link>
          </div>
        </Item>
        <ButtonItem colSpan={3}>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Submit Complaint'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Have an account? <Link to={'/login'}>Sign In</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };

